import styles from "./index.module.scss";
import { INotificationTab } from "@/shared/types/types";
import { useAppSelector } from "@/shared/redux/hooks";
import { getUserNotificationsCount } from "@/shared/redux/features/userSlice";
import Link from "next/link";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const Tabs = () => {
  const tabsStore: INotificationTab[] = useAppSelector(getUserNotificationsCount).tabs;
  return <div className={styles.wrap} data-sentry-component="Tabs" data-sentry-source-file="index.tsx">
      {tabsStore.length ? tabsStore.map((tab: INotificationTab) => <Link href={`/cabinet/notifications?tab=${tab.code}`} key={tab.code} className={styles.tab}>
              <p className={styles.text}>{tab.name}</p>
              {Number(tab.counts) != 0 ? <div className={styles.count}>{Number(tab.counts) < 100 ? tab.counts : "99+"}</div> : null}
            </Link>) : [1, 2, 3, 4, 5, 6].map((_, i) => <SkeletonLoader key={i} containerTag={"div"} width={213} height={24} borderRadius={4} />)}
    </div>;
};
export default Tabs;