import { useEffect, useState } from "react";
import ActionsProfile from "@/shared/components/header/components/ActionsProfile";
import ActionsCart from "@/shared/components/header/components/ActionsCart";
import ActionsFavourite from "@/shared/components/header/components/ActionsFavourite";
import ActionsChat from "@/shared/components/header/components/ActionsChat";
import Notifications from "@/shared/components/header/components/Notifications";
import { HintPopup } from "@/shared/components/popups";
import Tabs from "@/cabinet/Components/HintNotificationsTabs";
import HintProfileMenu from "@/cabinet/Components/HintProfileMenu";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { getAuthStatus, setSeenHintPopup } from "@/shared/redux/features/userSlice";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import useDebouncedFunction from "@/hooks/useDebouncedFunction";
import styles from "./index.module.scss";
const registerText = <p className={styles.registerText}>Войдите или зарегистрируйтесь, чтобы увидеть ваши персональные предложения</p>;
const HeaderActions = () => {
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const seenHintPopup: boolean = useAppSelector(getAuthStatus).seenHintPopup;
  const [isNotificationHintOpen, setIsNotificationHintOpen] = useState(false);
  const [isProfileHintOpen, setIsProfileHintOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("(max-width: 766px)");
  const handleMouseOver = (hint: string) => {
    if (hint === "notice") {
      setIsNotificationHintOpen(true);
      if (isProfileHintOpen) {
        setIsProfileHintOpen(false);
      }
    } else if (hint === "profile") {
      setIsProfileHintOpen(true);
      if (isNotificationHintOpen) {
        setIsNotificationHintOpen(false);
      }
    }
  };
  const dispatchedHint = useDebouncedFunction(() => {
    if (!isAuthUser && !seenHintPopup && !isMobile) {
      setIsNotificationHintOpen(true);
    } else {
      setIsNotificationHintOpen(false);
    }
  }, 500);
  useEffect(() => {
    dispatchedHint();
  }, [isAuthUser, seenHintPopup, isMobile]);
  return <div className={styles.wrap} data-sentry-component="HeaderActions" data-sentry-source-file="index.tsx">
      {!isMobile && <div className={styles.desktop}>
          <HintPopup isOpen={isNotificationHintOpen} setIsOpen={isAuthUser ? setIsNotificationHintOpen : () => null} position={"bottomRight"} color={isAuthUser ? "white" : "gray"} hasCloseButton={!isAuthUser} onClose={!isAuthUser ? () => dispatch(setSeenHintPopup()) : () => null}>
            <Notifications onMouseOver={() => isAuthUser ? handleMouseOver("notice") : null} onMouseOut={() => isAuthUser ? setIsNotificationHintOpen(false) : null} />
            {isAuthUser ? <Tabs /> : registerText}
          </HintPopup>
          <HintPopup isOpen={isProfileHintOpen} setIsOpen={setIsProfileHintOpen} position={"bottomRight"} color={isAuthUser ? "white" : "gray"}>
            <ActionsProfile onMouseOver={() => handleMouseOver("profile")} onMouseOut={() => setIsProfileHintOpen(false)} />
            {isAuthUser ? <HintProfileMenu /> : registerText}
          </HintPopup>
        </div>}
      {!isMobile && <ActionsCart />}
      {!isMobile && <ActionsFavourite />}
      <ActionsChat data-sentry-element="ActionsChat" data-sentry-source-file="index.tsx" />
    </div>;
};
export default HeaderActions;