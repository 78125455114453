"use client";

import React, { ForwardedRef } from "react";
import Button from "@/shared/components/Button";
import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
interface IHeaderCatalogButton {
  isOpen: boolean;
  setIsOpen: () => void;
  ref?: ForwardedRef<HTMLButtonElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
const HeaderCatalogButton = React.forwardRef((props: IHeaderCatalogButton, ref: any) => {
  const {
    isOpen,
    setIsOpen
  } = props;
  return <Button onClick={setIsOpen} className={styles.hamburger} ref={ref}>
      <>
        <Icon name={"Burger"} className={`${styles.icon} ${isOpen && styles.active}`} />
        <>Каталог</>
      </>
    </Button>;
});
HeaderCatalogButton.displayName = "HeaderCatalogButton";
export default HeaderCatalogButton;