import Image from "next/image";
import Link from "next/link";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import { Icon } from "@/shared/components/Icon";
import { li } from "@/utils/functions/li";
import styles from "./index.module.scss";
interface ISearchCatalogs {
  catalogs: {
    [key: string]: string | number;
    picture: string;
    sort: number;
  }[];
  closeModal: () => void;
}
const SearchCatalogs = (props: ISearchCatalogs) => {
  const {
    catalogs,
    closeModal
  } = props;
  const sortedCatalogs = catalogs ? catalogs.sort((a, b) => a.sort - b.sort) : [];
  const isMobile = useMediaQuery("(max-width: 767px)");
  return <div className={styles.wrap} data-sentry-component="SearchCatalogs" data-sentry-source-file="index.tsx">
      {sortedCatalogs.map((catalog, index: number) => {
      if (index > (isMobile ? 3 : 2)) return;
      return <Link prefetch={false} href={`${catalog.link}`} className={styles.item} key={index + "SearchCatalogs" + catalog.name} onClick={closeModal}>
            <div className={styles.info}>
              {catalog.picture && <Image className={styles.image} width={58} height={32} src={li(catalog.picture)} alt={`${catalog.name}`} />}
              <p className={styles.name}>{catalog.name}</p>
            </div>
            <Icon name={"Chevron"} className={styles.chevron} />
          </Link>;
    })}
    </div>;
};
export default SearchCatalogs;