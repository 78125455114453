import { useRef } from "react";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { IHeaderLink } from "@/shared/types/types";
import styles from "./index.module.scss";
interface IDropdown {
  setOpenDropdown: (argument: boolean) => void;
  openStatusPopup: () => void;
  list: IHeaderLink[];
}
const dropIn = {
  hidden: {
    top: "17px",
    opacity: 0
  },
  visible: {
    top: "22px",
    opacity: 1
  },
  exit: {
    top: "17px",
    opacity: 0
  }
};
export const Dropdown = (props: IDropdown) => {
  const {
    setOpenDropdown,
    openStatusPopup,
    list
  } = props;
  const ref = useRef(null);
  useOutsideClick(ref, () => setOpenDropdown(false));
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="Dropdown" data-sentry-source-file="index.tsx">
      <motion.ul className={styles.dropdownList} ref={ref} variants={dropIn} initial="hidden" animate="visible" exit="exit" onClick={e => e.stopPropagation()} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {list.map((el, i: number) => {
        if (el.name === "status") {
          return <li className={styles.dropdownItem} key={el.name + i + "_header_status"}>
                <button onClick={openStatusPopup} className={styles.dropdownStatus}>
                  Статус заказа
                </button>
              </li>;
        }
        return <li className={styles.dropdownItem} key={el.name + i + "_header_status"}>
              <Link prefetch={false} href={el.href} className={styles.dropdownLink}>
                {el.name}
              </Link>
            </li>;
      })}
      </motion.ul>
    </AnimatePresence>;
};