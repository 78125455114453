import { useEffect, useState } from "react";
import Link from "next/link";
import { useAppSelector } from "@/shared/redux/hooks";
import { getUserInfo } from "@/shared/redux/features/userSlice";
import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
const HeaderActionsCart = () => {
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter(item => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return <div className={styles.wrap} data-sentry-component="HeaderActionsCart" data-sentry-source-file="index.tsx">
      <Link prefetch={false} className={styles.link} href="/cart/" title="Корзина" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Icon name={"Cart"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        <span className={styles.count}>{isClient ? countCart : 0}</span>
      </Link>
    </div>;
};
export default HeaderActionsCart;