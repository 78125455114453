import { ICardMini } from "@/shared/types/types";
import Image from "next/image";
import { priceWithSpaces } from "@/utils/functions/price-with-spaces";
import Link from "next/link";
import Stars from "@/shared/components/Stars";
import { Icon } from "@/shared/components/Icon";
import { li } from "@/utils/functions/li";
import styles from "./index.module.scss";
interface ISearchCard {
  card: ICardMini;
  onClose: () => void;
}
const SearchCard = (props: ISearchCard) => {
  const {
    card,
    onClose
  } = props;
  return <Link prefetch={false} href={`/card/${card.id}/`} className={styles.wrap} onClick={onClose} data-sentry-element="Link" data-sentry-component="SearchCard" data-sentry-source-file="index.tsx">
      <div className={styles.left_side}>
        <Image alt={card.name} src={li(card.preview_picture)} height={200} width={200} className={styles.image} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <div className={styles.info}>
          <Stars className={styles.reviews} stars={card.reviews} data-sentry-element="Stars" data-sentry-source-file="index.tsx" />
          <p className={styles.name}>{card.name}</p>
        </div>
      </div>
      <div className={styles.right_side}>
        <div className={styles.prices}>
          {card.price_discount !== card.price ? <>
              {card.comission && <p className={styles.price}>{priceWithSpaces(card.price)}</p>}
              {card.price_discount && <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>}
              {!card.comission && <p className={styles.priceOld}>{priceWithSpaces(card.price)}</p>}
            </> : <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>}
        </div>
        <Icon name={"Chevron"} className={styles.chevron} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      </div>
    </Link>;
};
export default SearchCard;