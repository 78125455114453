"use client";

import React, { useEffect, useRef, useState } from "react";
import { ICatalogMiniItem, ISale } from "@/shared/types/types";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import { Icon } from "@/shared/components/Icon";
import Logo from "@/shared/components/header/components/Logo";
import CatalogButton from "@/shared/components/header/components/CatalogButton";
import NewCatalogModal from "@/shared/components/CatalogModal";
import HeaderActions from "@/shared/components/header/components/Actions";
import Nav from "@/shared/components/header/components/Nav";
import HeaderSearch from "@/shared/components/header/components/HeaderSearch";
import Menu from "@/shared/components/header/components/Menu";
import { useDisableScroll } from "@/hooks/useDisableScroll";
import { getCatalog } from "@/utils/api/catalog";
import styles from "./index.module.scss";
interface IBottom {
  catalog: ICatalogMiniItem[] | false;
  sales: ISale[] | false;
}
const Bottom = (props: IBottom) => {
  const {
    catalog,
    sales
  } = props;
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [catalogVisible, setCatalogVisible] = useState<boolean>(false);
  const [catalogValue, setCatalogValue] = useState<ICatalogMiniItem[] | false>(catalog);
  const [salesValue, setSalesValue] = useState<ISale[] | false>(sales);
  const headerCatalogRef = useRef<any>(null);
  const headerCatalogButtonRef = useRef<any>(null);
  const headerSearchRef = useRef<HTMLDivElement>(null);
  const catalogModalRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const {
    disableScroll
  } = useDisableScroll();
  const handleClickOutside = (event: MouseEvent) => {
    const headerCatalog = headerCatalogRef.current;
    const catalogButton = headerCatalogButtonRef.current;
    const catalogModal = catalogModalRef.current;
    if (catalogButton && catalogButton.contains(event.target as Node)) {
      return;
    }
    if (catalogModal && !catalogModal.contains(event.target as Node)) {
      if (catalogVisible) {
        return;
      }
      handleCloseCatalog();
      return;
    }
    if (headerCatalog && !headerCatalog.contains(event.target)) {
      if (!!headerSearchRef.current && headerSearchRef.current?.contains(event.target as Node)) {
        setIsSearchOpen(!isSearchOpen);
        disableScroll();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleCloseCatalog = () => {
    setCatalogVisible(false);
  };
  const toggleVisible = async (isHeader = true) => {
    try {
      if (catalogValue && catalogValue.length) return;
      const result = await getCatalog();
      if (result && result.items) {
        if (result.sales) {
          setSalesValue(result.sales);
        }
        setCatalogValue(result.items);
      }
    } catch (err) {
      console.log("Ошибка загрузки каталога", err);
    } finally {
      if (!isHeader) return;
      setCatalogVisible(!catalogVisible);
    }
  };
  return <>
      <div className={styles.bottomWrap}>
        {isSearchOpen && isMobile ? <button onClick={() => setIsSearchOpen(false)} className={styles.close_search} title="Назад">
            <Icon name={"ArrowSearch"} className={styles.close_icon} fill="#1D1D1D" />
          </button> : <Menu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />}
        <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
        <CatalogButton isOpen={catalogVisible} setIsOpen={toggleVisible} ref={headerCatalogButtonRef} data-sentry-element="CatalogButton" data-sentry-source-file="index.tsx" />
        {!!catalogValue && !!catalogValue.length ? <NewCatalogModal sales={salesValue} catalog={catalogValue} visible={catalogVisible} handleClose={handleCloseCatalog} ref={catalogModalRef} /> : null}
        <HeaderSearch isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} ref={headerSearchRef} data-sentry-element="HeaderSearch" data-sentry-source-file="index.tsx" />
        <HeaderActions data-sentry-element="HeaderActions" data-sentry-source-file="index.tsx" />
      </div>
      <Nav setIsOpenMenu={setIsOpenMenu} catalog={catalogValue} sales={salesValue} loadCatalog={async () => await toggleVisible(false)} data-sentry-element="Nav" data-sentry-source-file="index.tsx" />
    </>;
};
export default Bottom;