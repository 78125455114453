"use client";

import { useEffect, useState } from "react";
import { useAppSelector } from "@/shared/redux/hooks";
import { getAuthStatus, getUserInfo } from "@/shared/redux/features/userSlice";
import { useRouter } from "next/navigation";
import { Icon } from "@/shared/components/Icon";
import { useAuthModal } from "@/hooks/useAuthModal";
import styles from "./index.module.scss";
const HeaderActionsFavourite = () => {
  const countFavourites: number = useAppSelector(getUserInfo).info.favourites.length;
  const router = useRouter();
  const {
    handleOpenAuthModal
  } = useAuthModal();
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const handleClick = () => isAuthUser ? router.push("/cabinet/favourites") : handleOpenAuthModal();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return <>
      <div className={styles.wrap}>
        <button onClick={handleClick} className={styles.button} title="Избранное">
          <Icon name={"Favourite"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          <span className={styles.count}>{isClient ? countFavourites : 0}</span>
        </button>
      </div>
    </>;
};
export default HeaderActionsFavourite;