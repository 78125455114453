import styles from "./index.module.scss";
import { ICatalogMiniItem, ICatalogObjDepths, ISale } from "@/shared/types/types";
import React, { forwardRef, useEffect, useState } from "react";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import { Icon } from "@/shared/components/Icon";
import { useRouter } from "next/navigation";
import { rejectedCatalogs } from "@/shared/helpers/data/catalogHelpers";
import List from "@/shared/components/CatalogModal/components/List";
import Detail from "@/shared/components/CatalogModal/components/Detail";
import { useDisableScroll } from "@/hooks/useDisableScroll";
interface ICatalogModalProps {
  sales: ISale[] | false;
  catalog: ICatalogMiniItem[];
  visible: boolean;
  handleClose: () => void;
}
const salesInfo = {
  code: "sales",
  id: "sales",
  modal_picture: "catalog_sale_detail.png",
  name: "Акции"
};
const NewCatalogModal = forwardRef((props: ICatalogModalProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    catalog = [],
    visible = false,
    handleClose = () => {},
    sales = []
  } = props;
  const firstCatalog = catalog[0];
  const firstDepths = Object.keys(firstCatalog.depths).map(item => firstCatalog.depths[item]);
  const [currentCatalog, setCurrentCatalog] = useState<ICatalogObjDepths[]>(firstDepths);
  const [currentCatalogInfo, setCurrentCatalogInfo] = useState<ICatalogMiniItem>(salesInfo as ICatalogMiniItem);
  const [catalogWithoutDepths, setCatalogWithoutDepths] = useState<boolean>(false);
  const [listWithoutDepths, setListWithoutDepths] = useState<ICatalogObjDepths[]>([]);
  const [listWithDepths, setListWithDepths] = useState<ICatalogObjDepths[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isChoice, setIsChoice] = useState<boolean>(false);
  const [isSales, setIsSales] = useState<boolean>(true);
  const {
    disableScroll,
    enableScroll
  } = useDisableScroll();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const router = useRouter();
  const handleClickCatalog = (id: string, code: string = "") => {
    if (id === "sales" && code === "sales") {
      setIsSales(true);
      setCurrentCatalogInfo(salesInfo as ICatalogMiniItem);
      if (isMobile) setIsChoice(true);
      return;
    }
    const findCatalog = catalog.find((item: ICatalogMiniItem) => item.id === id);
    setIsSales(false);
    if (!findCatalog) return;
    if (!findCatalog.depths) {
      setCurrentCatalog([]);
      setCurrentCatalogInfo(findCatalog);
      setListWithDepths([]);
      setCatalogWithoutDepths(false);
      setListWithoutDepths([]);
      if (isMobile) {
        router.push(`/catalog/${code}/`);
        handleClose();
      }
      if (!loading) setLoading(true);
      return;
    } else {
      const newDepths = Object.keys(findCatalog.depths).map(item => {
        return findCatalog.depths[item];
      });
      const withoutDepths = newDepths.every(item => !item.depths);
      const withoutDepthsFilter = newDepths.filter(item => !item.depths);
      const withDepths = newDepths.filter(item => item.depths).sort((a, b) => {
        const depthA = a.depths ? Object.keys(a.depths).length : 0;
        const depthB = b.depths ? Object.keys(b.depths).length : 0;
        return depthB - depthA;
      });
      const sortedDepths = [...withoutDepthsFilter, ...withDepths];
      setListWithDepths(withDepths);
      setCatalogWithoutDepths(withoutDepths);
      setListWithoutDepths(withoutDepthsFilter);
      setCurrentCatalogInfo(findCatalog);
      if (isMobile) setIsChoice(true);
      setCurrentCatalog(sortedDepths);
      if (!loading) setLoading(true);
      return;
    }
  };
  const handleBack = () => {
    if (!isChoice) {
      handleClose();
      return;
    }
    setIsChoice(false);
    setCurrentCatalog(firstDepths);
    setCurrentCatalogInfo(firstCatalog);
  };
  useEffect(() => {
    if (!loading) {
      if (isSales) {
        handleClickCatalog("sales", "sales");
      } else {
        handleClickCatalog(firstCatalog.id, firstCatalog.code);
      }
    }
    if (visible) disableScroll();else enableScroll();
  }, [visible]);
  let catalogList = catalog.slice(1).concat(catalog[0]);
  return <>
      <div className={`${styles.catalog_wrap} ${visible ? styles.visible : "visually-hidden"}`} ref={ref}>
        <div className={styles.catalog_header}>
          {isMobile ? <button className={styles.back_button} onClick={handleBack}>
              <Icon name={"Chevron"} className={styles.icon} />
              {isChoice ? "Все категории" : "Категории"}
            </button> : null}
          <button onClick={handleClose} className={styles.close}>
            <Icon name={"Close"} />
          </button>
        </div>
        <div className={styles.content}>
          <List isSales={sales && !!sales.length} catalog={catalogList} rejectedCatalogs={rejectedCatalogs} isChoice={isChoice} handleClickCatalog={handleClickCatalog} currentCatalogCode={currentCatalogInfo.code} handleClose={handleClose} />
          <Detail currentCatalogInfo={currentCatalogInfo} isChoice={isChoice} currentCatalog={currentCatalog} noDepths={catalogWithoutDepths} listWithoutDepths={listWithoutDepths} listWithDepths={listWithDepths} handleClose={handleClose} isSales={isSales} sales={sales} />
        </div>
      </div>
      {visible ? <div className={styles.shadow} /> : null}
    </>;
});
NewCatalogModal.displayName = "NewCatalogModal";
export default NewCatalogModal;