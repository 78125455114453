"use client";

import Link from "next/link";
import styles from "./index.module.scss";
import Picture from "@/shared/components/Picture";
import SaleButton from "@/shared/components/SaleButton/sale-button";
import { useEffect, useState } from "react";
import { checkBigSale } from "@/utils/api/check-big-sale";
import { ISaleData } from "@/shared/types/types";
const Marquee = () => {
  const [saleData, setSaleData] = useState<ISaleData | false>(false);
  const dev = process.env.NODE_ENV === "development";
  useEffect(() => {
    try {
      checkBigSale(dev).then(res => {
        if (!res.error_msg && res.success) {
          setSaleData(res);
        }
      });
    } catch (err) {
      console.log(err);
      setSaleData(false);
    }
  }, []);
  if (!saleData) return null;
  return <>
      {saleData.data.top_plashka_picture ? <noindex>
          <Link prefetch={false} id={"custom-marquee"} className={styles.link} href={saleData.data.property_run_line_link}>
            <Picture className={styles.picture} desktop={`https://ohotaktiv.ru${saleData.data.top_plashka_picture}`} mobile={`https://ohotaktiv.ru${saleData.data.top_plashka_picture_mobile}`} alt="Плашка с акцией" width={1907} height={64} />
          </Link>
        </noindex> : null}
      <SaleButton saleData={saleData} data-sentry-element="SaleButton" data-sentry-source-file="index.tsx" />
    </>;
};
export default Marquee;