// import { useEffect } from "react";
//
// export function useOutsideClick(ref: any, onClickOut: () => void, deps = []) {
//   useEffect(() => {
//     console.log("asdasdasdasd", ref);
//     const onClick = ({target}: any) => {
//       !ref?.contains(target) && onClickOut();
//     };
//     document.addEventListener("click", onClick);
//     return () => document.removeEventListener("click", onClick);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [deps]);
// }
import React, { useEffect } from "react";
export const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void, capture = false) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };
    document.addEventListener("click", handleClickOutside, capture);
    return () => {
      document.removeEventListener("click", handleClickOutside, capture);
    };
  }, [callback, ref, capture]);
};