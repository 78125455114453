import Link from "next/link";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import { scrollToTop } from "@/utils/functions/scrollToTop";
import { li } from "@/utils/functions/li";
import styles from "./index.module.scss";
const Logo = () => {
  const pathname = usePathname();
  const router = useRouter();
  const handleScroll = () => {
    router.refresh();
    scrollToTop();
  };
  return <div className={styles.logoWrap} data-sentry-component="Logo" data-sentry-source-file="index.tsx">
      {pathname !== "/" ? <Link prefetch={false} href="/" className={styles.link}>
          <Image className={styles.logo} alt="Интернет-магазин товаров для охоты и рыбалки ОхотАктив" title="Интернет-магазин товаров для охоты и рыбалки ОхотАктив" src={li("logo-header.webp")} height={50} width={50} />
        </Link> : <button className={styles.link} onClick={handleScroll}>
          <Image className={styles.logo} alt="Интернет-магазин товаров для охоты и рыбалки ОхотАктив" title="Интернет-магазин товаров для охоты и рыбалки ОхотАктив" src={li("logo-header.webp")} width={50} height={50} />
        </button>}
    </div>;
};
export default Logo;