"use client";

import { useEffect, useRef, useState } from "react";
import helper from "@/shared/helpers/data/headerLinksData.json";
import Link from "next/link";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import More from "@/shared/components/header/components/More";
import styles from "./index.module.scss";
const LinksList = () => {
  const listRef = useRef<HTMLUListElement>(null);
  const [hiddenList, setHiddenList] = useState<typeof helper>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const isDesktop = useMediaQuery("(max-width: 1440px)");
  const gap = isDesktop ? 24 : 32;
  useEffect(() => {
    const updateRows = () => {
      if (!listRef.current) return;
      const items = Array.from(listRef.current.children) as HTMLLIElement[];
      const newRows: string[][] = [];
      let currentRowTop: number | null = null;
      let currentRow: string[] = [];
      items.forEach(item => {
        const top = item.offsetTop;
        const name = item.textContent || "";
        if (currentRowTop === null || top === currentRowTop) {
          currentRow.push(name);
        } else {
          newRows.push(currentRow);
          currentRow = [name];
        }
        currentRowTop = top;
      });
      if (currentRow.length) {
        newRows.push(currentRow);
      }
      const hidden = helper.filter(el => !newRows[0].includes(el.name));
      setShowDropdown(!!newRows[1]);
      setHiddenList(hidden);
    };
    updateRows();
    window.addEventListener("resize", updateRows);
    return () => {
      window.removeEventListener("resize", updateRows);
    };
  }, [listRef.current]);
  const difference = 51 + gap * 2;
  return <nav className={styles.listWrap} itemScope itemType="http://schema.org/SiteNavigationElement" data-sentry-component="LinksList" data-sentry-source-file="index.tsx">
      <ul className={styles.list} itemProp="about" itemScope itemType="http://schema.org/ItemList" ref={listRef} style={{
      width: `calc(100% - ${showDropdown ? difference : 0}px)`
    }}>
        {helper.map((el, i: number) => {
        return <li className={styles.item} itemProp="itemListElement" itemScope itemType="http://schema.org/ItemList" key={el.name + "_header_link"}>
              <Link prefetch={false} href={el.href} className={styles.link} itemProp="url">
                {el.name}
              </Link>
              <meta itemProp="name" content={el.content} />
            </li>;
      })}
      </ul>
      {showDropdown ? <div className={styles.dropdown}>
          <More list={hiddenList} />
        </div> : null}
    </nav>;
};
export default LinksList;