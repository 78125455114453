"use client";

import styles from "./index.module.scss";
import { Icon } from "@/shared/components/Icon";
import { useAppSelector } from "@/shared/redux/hooks";
import { getAuthStatus } from "@/shared/redux/features/userSlice";
import { useEffect, useState } from "react";
import { useAuthModal } from "@/hooks/useAuthModal";
import { getCookie, setCookie } from "@/utils/functions/cookie";
import { keyGenerator } from "@/utils/functions/keyGenerator";
import clsx from "clsx";
interface IGreetingBarProps {
  isDesktop?: boolean;
  isMobile?: boolean;
}
const GreetingBar = (props: IGreetingBarProps) => {
  const {
    isDesktop,
    isMobile
  } = props;
  const [isShowBar, setIsShowBar] = useState(false);
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const {
    handleOpenAuthModal
  } = useAuthModal();
  const [isClient, setIsClient] = useState(false);
  const isSeen = isClient ? getCookie("seenGreetingBar") : false;
  useEffect(() => {
    if (!isSeen && !isAuthUser) {
      setIsShowBar(true);
    } else {
      setIsShowBar(false);
      cookie();
    }
  }, [isShowBar, isAuthUser]);
  const cookie = () => {
    setCookie("seenGreetingBar", keyGenerator(), {
      expires: 60 * 60 * 24
    });
    const check = getCookie("seenGreetingBar");
    if (check) setIsShowBar(false);
  };
  const handleCloseBar = (e: any) => {
    e.stopPropagation();
    setIsShowBar(false);
    cookie();
  };
  useEffect(() => {
    if (!isClient) setIsClient(true);
  }, []);
  if (!isClient) return null;
  return <div id={"greetingBar"} className={clsx(styles.wrap, isShowBar && isDesktop && styles.wrapVisibleDesktop, isShowBar && isMobile && styles.wrapVisibleMobile)} onClick={handleOpenAuthModal} data-sentry-component="GreetingBar" data-sentry-source-file="index.tsx">
      <div className={styles.content}>
        {!isMobile ? <div className={styles.textContainer}>
            <p className={styles.textMain}>Получить промокод на 1 000₽ за регистрацию* </p>
            <p className={styles.textSmall}>*только для новых пользователей при покупке от 5 000₽</p>
          </div> : <div className={styles.textContainer}>
            <p className={styles.textMain}>Промокод на 1 000₽ за регистрацию* </p>
            <p className={styles.textSmall}>*только новым пользователям при покупке от 5 000₽</p>
          </div>}
        <div className={styles.arrow}>
          <Icon name={"Chevron"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        </div>
      </div>

      <button className={styles.closeButton} onClick={handleCloseBar}>
        <Icon name={"Close"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      </button>
    </div>;
};
export default GreetingBar;