"use client";

import { useEffect, useState } from "react";
import { getAuthStatus, getUserNotifications, getUserNotificationsCount } from "@/shared/redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { Icon } from "@/shared/components/Icon";
import { useRouter } from "next/navigation";
import { useAuthModal } from "@/hooks/useAuthModal";
import styles from "./index.module.scss";
interface INotificationsProps {
  onMouseOver: () => void;
  onMouseOut: () => void;
}
const Notifications = (props: INotificationsProps) => {
  const {
    onMouseOver,
    onMouseOut
  } = props;
  const countNotifications: number = useAppSelector(getUserNotificationsCount).count;
  const router = useRouter();
  const {
    handleOpenAuthModal
  } = useAuthModal();
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const handleClick = () => isAuthUser ? router.push("/cabinet/notifications") : handleOpenAuthModal();
  const dispatch = useAppDispatch();
  const [isClient, setIsClient] = useState<boolean>(false);
  useEffect(() => {
    isAuthUser && dispatch(getUserNotifications());
    setIsClient(true);
  }, []);
  return <>
      <button onClick={handleClick} className={styles.button} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <Icon name={countNotifications && countNotifications != 0 ? "has-notifications" : "no-notifications"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      </button>
    </>;
};
export default Notifications;