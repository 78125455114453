"use client";

import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
const ActionsChat = () => {
  const handleClick = () => {
    const icon: HTMLButtonElement | null = document.querySelector(".consultant-icon");
    if (icon) {
      icon.click();
    }
    setTimeout(() => {
      const chat: HTMLButtonElement | null = document.querySelector(".messengers__item_consultant");
      if (chat) {
        chat.click();
      }
    }, 1);
  };
  // const w = typeof window !== "undefined" ? (window as any) : {};
  // const handleClick = () => {
  //   if (w.openRetailCRMChat) {
  //     w.openRetailCRMChat();
  //   }
  // };
  return <div className={styles.wrap} data-sentry-component="ActionsChat" data-sentry-source-file="index.tsx">
      <button className={styles.button} onClick={handleClick} title="Чат с консультантом">
        <Icon name={"Chat"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      </button>
    </div>;
};
export default ActionsChat;