import styles from "./index.module.scss";
import Image from "next/image";
import Link from "next/link";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import { ICatalogMiniItem } from "@/shared/types/types";
import clsx from "clsx";
import { Icon } from "@/shared/components/Icon";
import { li } from "@/utils/functions/li";
interface ICatalogList {
  isChoice: boolean;
  catalog: ICatalogMiniItem[];
  handleClickCatalog: (id: string, code?: string) => void;
  rejectedCatalogs: string[];
  currentCatalogCode: string;
  handleClose: () => void;
  isSales: boolean;
}
const List = (props: ICatalogList) => {
  const {
    isChoice,
    rejectedCatalogs,
    catalog,
    handleClickCatalog,
    currentCatalogCode = "",
    handleClose,
    isSales
  } = props;
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const saleItem = <Link href={"/sales/"} className={clsx(styles.sale, styles.catalog, currentCatalogCode === "sales" && styles.active)} prefetch={false} onMouseEnter={() => {
    if (isMobile) return;else handleClickCatalog("sales", "sales");
  }} onClick={handleClose}
  // onClick={() => {
  //   if (!isMobile) handleClose();
  //   else handleClickCatalog("sales", "sales");
  // }}
  >
      <p className={styles.catalog_name}>Акции</p>
      <Icon name={"catalog-sales"} className={styles.sale_icon} />
    </Link>;
  return <div className={`${styles.list} ${isChoice ? styles.hidden : ""}`} data-sentry-component="List" data-sentry-source-file="index.tsx">
      {isSales ? saleItem : null}
      {catalog.map((item, index: number) => {
      const content = <>
            {isMobile ? <Image src={li(item.catalog_dickpic || item.picture)} alt={`Изображение каталога ${item.name}`} width={200} height={200} className={styles.catalog_image} /> : null}
            {isMobile && rejectedCatalogs.includes(item.code) ? null : <div className={styles.catalog_name}>{item.name}</div>}
          </>;
      if (isMobile && item.depths) {
        return <button key={`${index}_Catalog_${item.id}`} className={clsx(styles.catalog, currentCatalogCode === item.code ? styles.active : "")} onMouseEnter={() => {
          if (isMobile) return;else handleClickCatalog(item.id, item.code);
        }} onClick={() => {
          if (!isMobile) handleClose();else handleClickCatalog(item.id, item.code);
        }}>
              {content}
            </button>;
      }
      return <Link key={`${index}_Catalog_${item.id}`} href={`/catalog/${item.code}`} prefetch={false} className={clsx(styles.catalog, currentCatalogCode === item.code ? styles.active : "")} onMouseEnter={() => {
        if (isMobile) return;else handleClickCatalog(item.id, item.code);
      }} onClick={() => {
        if (!isMobile) handleClose();else handleClickCatalog(item.id, item.code);
      }}>
            {content}
          </Link>;
    })}
    </div>;
};
export default List;