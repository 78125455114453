"use client";

import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useAppSelector } from "@/shared/redux/hooks";
import { getAuthStatus, getUserInfo } from "@/shared/redux/features/userSlice";
import { Icon } from "@/shared/components/Icon";
import { useAuthModal } from "@/hooks/useAuthModal";
import styles from "./index.module.scss";
interface IMenuButton {
  closeMenu: () => void;
}
const MenuButton = (props: IMenuButton) => {
  const {
    closeMenu
  } = props;
  const router = useRouter();
  const isAuthUser = useAppSelector(getAuthStatus).isAuth;
  const {
    handleOpenAuthModal
  } = useAuthModal();
  const userName = useAppSelector(getUserInfo).info.name;
  const [isClient, setIsClient] = useState<boolean>(false);
  const displayText = isClient ? isAuthUser ? userName ? userName : "Профиль" : "Войти" : "Войти";
  const handleClick = () => {
    if (isAuthUser) {
      router.push("/cabinet/");
      closeMenu();
    } else {
      handleOpenAuthModal();
    }
  };
  useEffect(() => setIsClient(true), []);
  return <>
      <button onClick={handleClick} className={styles.button}>
        <Icon name={"Personal"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        {displayText}
      </button>
    </>;
};
export default MenuButton;