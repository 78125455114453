import { FC } from "react";
import { useOpenModal } from "@/hooks/useOpenModal";
import { useAppSelector } from "@/shared/redux/hooks";
import { getCity } from "@/shared/redux/features/citySlice";
import { Icon } from "@/shared/components/Icon";
import { GeoPopup } from "@/shared/components/popups";
import styles from "./index.module.scss";
const GeoButton: FC = () => {
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const city = useAppSelector(getCity).city;
  return <>
      {showModal && <GeoPopup isOpen={showModal} onClose={handleCloseModal} />}
      <button onClick={handleOpenModal} className={styles.button}>
        <Icon name={"Geo"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        {city}
      </button>
    </>;
};
export default GeoButton;