"use client";

import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useAppDispatch } from "@/shared/redux/hooks";
import { createPortal } from "react-dom";
import { setCity, setFirstTime } from "@/shared/redux/features/citySlice";
import { getCityRequest } from "@/utils/api/getCity";
import { getQueryCity } from "@/utils/api/cities";
import { createServerCookie } from "@/app/actions";
import { setCookie } from "@/utils/functions/cookie";
import { setDefaultCity } from "@/utils/functions/setDefaultCity";
import { isClient } from "@/utils/functions/isClient";
import Button from "@/shared/components/Button";
import { Icon } from "@/shared/components/Icon";
interface IFirstTime {
  onClose: () => void;
  anotherCity: () => void;
}
const FirstTime: FC<IFirstTime> = props => {
  const {
    onClose,
    anotherCity
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [cityState, setCityState] = useState<string>("Москва");
  const [cityCode, setCityCode] = useState<string>("moskva");
  const dispatch = useAppDispatch();
  const modalRoot = isClient() ? document.getElementById("modal-definition") : {} as Element;
  useEffect(() => {
    getCityRequest().then(res => {
      if (!!res.city) {
        setCityState(res.city.name);
        setCityCode(res.city.code);
      }
      setTimeout(() => {
        if (typeof window !== "undefined" && window.ym) {
          window.ym(42989679, "reachGoal", "showSetCity");
        }
      }, 2000);
    });
    setIsLoaded(true);
  }, []);
  const cityCorrect = async () => {
    try {
      dispatch(setFirstTime(false));
      if (typeof window !== "undefined" && window.ym) {
        window.ym(42989679, "reachGoal", "wellDefinedCity");
      }
      const result = await getQueryCity(cityCode);
      if (result && result.success && result.city) {
        const city = result.city;
        const plainCity = {
          name: city.name,
          code: city.code
        };
        dispatch(setCity(plainCity));
        const cookie = {
          ...plainCity,
          lat: city.geo_lat,
          lon: city.geo_lon
        };
        try {
          await createServerCookie("choosedCity", JSON.stringify(cookie));
        } catch (err) {
          console.log(err);
        } finally {
          setCookie("choosedCity", JSON.stringify(cookie));
          onClose();
        }
      } else {
        dispatch(setCity({
          name: "Москва",
          code: "moskva"
        }));
        await setDefaultCity();
        onClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      onClose();
    }
  };
  const cityIncorrect = () => {
    anotherCity();
    onClose();
  };
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onClose) {
          onClose();
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  if (!isLoaded) return null;
  return createPortal(<>
      <div className={styles.wrap}>
        <div className={styles.head}>
          <p className={styles.title}>Ваш город {cityState}?</p>
          <button onClick={onClose} title="Закрыть">
            <Icon name={"Close"} />
          </button>
        </div>
        <p className={styles.text}>Это поможет вам заранее увидеть условия доставки товаров и цены в Вашем городе.</p>
        <div className={styles.buttons_wrap}>
          <Button onClick={cityCorrect} className={`${styles.button} ${styles.button_correct}`}>
            <>Да, верно</>
          </Button>
          <Button onClick={cityIncorrect} className={`${styles.button} ${styles.button_incorrect}`}>
            <>Нет, другой</>
          </Button>
        </div>
      </div>
    </>, modalRoot!);
};
export default FirstTime;