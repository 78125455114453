import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { getSearchList, removeItemSearch } from "@/shared/redux/features/searchSlice";
import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
interface ISearchHistory {
  onClickPopular: (value: string) => void;
}
const SearchHistory = (props: ISearchHistory) => {
  const {
    onClickPopular
  } = props;
  const searchList = useAppSelector(getSearchList);
  const dispatch = useAppDispatch();
  const handleCloseClick = (value: string) => dispatch(removeItemSearch(value));
  return searchList.length > 0 && <div className={styles.wrap}>
        <p className={styles.title}>История</p>
        <div className={styles.buttonList}>
          {searchList.map((search, index: number) => {
        return <div key={`${index + "SearchHistory" + "searchList"}`} className={styles.buttonWrap}>
                <button onClick={() => onClickPopular(search)} className={styles.button}>
                  <Icon name={"Repeat"} />
                  <p className={styles.buttonText}>{search.replace(/[+]/g, " ")}</p>
                </button>
                <button className={styles.buttonClose} onClick={() => handleCloseClick(search)}>
                  <Icon name={"Close"} className={styles.icon} />
                </button>
              </div>;
      })}
        </div>
      </div>;
};
export default SearchHistory;