import styles from "./index.module.scss";
import Image from "next/image";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import { ICatalogObjDepths } from "@/shared/types/types";
import Link from "next/link";
import { getNoun } from "@/utils/functions/getNoun";
import { li } from "@/utils/functions/li";
interface IDetailElem {
  newDepths: (ICatalogObjDepths | false)[] | false;
  catalog: ICatalogObjDepths;
  handleClose?: () => void;
}
const DetailElem = (props: IDetailElem) => {
  const {
    newDepths = false,
    catalog,
    handleClose = () => {}
  } = props;
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const more = newDepths ? getNoun(newDepths.length - 5, "категория", "категории", "категорий") : false;
  return <div className={styles.catalog} data-sentry-component="DetailElem" data-sentry-source-file="index.tsx">
      {isMobile ? <Link href={`/catalog/${catalog.code}/`} className={styles.catalog_elem} onClick={handleClose} prefetch={false}>
          <Image src={li(catalog.catalog_dickpic || catalog.picture)} alt={`Изображение каталога ${catalog.name}`} width={147} height={147} className={styles.catalog_elem_image} />
          <p className={styles.catalog_elem_name}>{catalog.name}</p>
        </Link> : <Link href={`/catalog/${catalog.code}/`} className={styles.catalog_name} onClick={handleClose} prefetch={false}>
          {catalog.name}
        </Link>}
      {!isMobile && newDepths && newDepths.length ? <div className={styles.catalog_depths}>
          {newDepths.map((dep: any, depIndex: number) => {
        if (depIndex >= 5 && !isMobile) return null;
        return <Link onClick={handleClose} href={`/catalog/${dep.code}`} key={depIndex + dep.name} className={styles.catalog_depths_elem} prefetch={false}>
                {dep.name}
              </Link>;
      })}
          {newDepths.length > 5 && more ? <Link onClick={handleClose} href={`/catalog/${catalog.code}/`} className={styles.more} prefetch={false}>
              Еще {newDepths.length - 5} {more}
            </Link> : null}
        </div> : null}
    </div>;
};
export default DetailElem;