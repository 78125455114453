"use client";

import { checkUserAuth, getAuthStatus, getUserInfo } from "@/shared/redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Icon } from "@/shared/components/Icon";
import { useAuthModal } from "@/hooks/useAuthModal";
import styles from "./index.module.scss";
interface IActionsProfile {
  isLink?: boolean;
  onMouseOver?: () => void | null;
  onMouseOut?: () => void | null;
}
const ActionsProfile = (props: IActionsProfile) => {
  const {
    isLink = true,
    onMouseOver,
    onMouseOut
  } = props;
  const router = useRouter();
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const userName: string = useAppSelector(getUserInfo).info.name;
  const [isClient, setIsClient] = useState<boolean>(false);
  const {
    handleOpenAuthModal
  } = useAuthModal();
  useEffect(() => setIsClient(true), []);
  const handleClick = () => {
    if (!isLink) return;
    isAuthUser ? router.push("/cabinet/personal-data/") : handleOpenAuthModal();
  };
  const displayText = isClient ? isAuthUser ? userName ? userName : "Профиль" : "Войти" : "Войти";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkUserAuth());
  }, []);
  return <>
      <button onClick={handleClick} className={styles.button} onMouseOver={onMouseOver} onMouseOut={onMouseOut} title="Личный кабинет">
        <>
          <Icon name={"Personal"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          {displayText}
        </>
      </button>
    </>;
};
export default ActionsProfile;