import { request } from "../functions/request";
export const getCityRequest = async () => {
  return await request("/geoip/index.php", {
    headers: {
      Cookie: "wsr_nocache=1"
    },
    next: {
      revalidate: 0
    }
  });
};