import Link from "next/link";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/shared/redux/hooks";
import { getUserInfo } from "@/shared/redux/features/userSlice";
import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
const CartButton = () => {
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter(item => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return <Link prefetch={false} href="/cart/" className={styles.button} data-sentry-element="Link" data-sentry-component="CartButton" data-sentry-source-file="index.tsx">
      <Icon name={"Cart"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      Корзина
      <span className={styles.count}>{isClient ? countCart : 0}</span>
    </Link>;
};
export default CartButton;