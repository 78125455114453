import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","success","error"] */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["RetailCRM"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/analytics/retail-crm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/GreetingBar/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/header/components/Content/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/Marquee/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/Toast/index.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/utils/functions/request-client.ts");
