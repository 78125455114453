import styles from "./index.module.scss";
import Link from "next/link";
import { profileMenuList } from "@/shared/constants/constants";
const HintProfileMenu = () => {
  return <div className={styles.wrap} data-sentry-component="HintProfileMenu" data-sentry-source-file="index.tsx">
      {profileMenuList.map(item => {
      return <Link href={item.link} key={item.section} className={styles.tab}>
            <p className={styles.text}>{item.title}</p>
          </Link>;
    })}
    </div>;
};
export default HintProfileMenu;