"use client";

import { forwardRef, useContext } from "react";
import { SearchContext } from "@/shared/contexts/searchContext";
import { ICardMini } from "@/shared/types/types";
import SearchPopular from "@/shared/components/header/components/SearchPopular";
import SearchCatalogs from "@/shared/components/header/components/SearchCatalogs";
import SearchHistory from "@/shared/components/header/components/SearchHistory";
import SearchCardsList from "@/shared/components/header/components/SearchCardsList";
import SearchModalSkeleton from "@/shared/components/skeleton-loader/tamplates/SearchModalSkeleton";
import { useDisableScroll } from "@/hooks/useDisableScroll";
import styles from "./index.module.scss";
interface ISearchModal {
  items?: ICardMini[];
  query: string;
  searchKeys?: string[];
  onClickPopular: (value: string) => void;
  closeSearch?: (arg?: boolean) => void;
  isOpen?: boolean;
  searchRedirect?: string;
  searchCatalogs: {
    [key: string]: string | number;
    sort: number;
  }[];
  isFetching: boolean;
}
const SearchModal = forwardRef<HTMLDivElement, ISearchModal>((props, ref) => {
  const {
    items = [],
    query,
    searchKeys,
    onClickPopular,
    closeSearch,
    isOpen,
    searchRedirect,
    searchCatalogs,
    isFetching
  } = props;
  const {
    startedItems,
    keys,
    catalogs,
    contextLoading
  } = useContext(SearchContext) as any;
  const {
    enableScroll
  } = useDisableScroll();
  const closeModal = () => {
    if (closeSearch) {
      closeSearch(!isOpen);
    }
    if (!isOpen) {
      enableScroll();
    }
  };
  const itemsToShow = !!query.length ? items.length ? items : [] : startedItems;
  const popularKeys = searchKeys && !!searchKeys.length ? searchKeys : keys;
  const catalogsList = searchCatalogs && query ? searchCatalogs : catalogs;
  if (!itemsToShow && !popularKeys && !catalogsList) return null;
  return <div id="search-wrap" className={styles.wrap} ref={ref}>
      <div className={styles.container}>
        {isFetching && query || contextLoading ? <SearchModalSkeleton /> : <>
            {!query.length && <div className={styles.head}>
                <SearchHistory onClickPopular={onClickPopular} />
                <SearchPopular keys={popularKeys} onClickPopular={onClickPopular} />
              </div>}
            <SearchCatalogs closeModal={closeModal} catalogs={catalogsList} />
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <SearchCardsList items={itemsToShow} showButton={items && items.length > 4 && query.length > 0} query={query} onClick={closeModal} cardMiniDisable={isOpen} searchRedirect={searchRedirect} />
          </>}
      </div>
    </div>;
});
SearchModal.displayName = "SearchModal";
export default SearchModal;