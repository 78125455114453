"use client";

import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { Icon } from "@/shared/components/Icon";
import GeoButton from "@/shared/components/header/components/GeoButton";
import MenuButton from "@/shared/components/header/components/MenuButton";
import CartButton from "@/shared/components/header/components/CartButton";
import CallbackButton from "@/shared/components/header/components/CallbackButton";
import { supportNumber, supportsNumberHref } from "@/shared/constants/constants";
import styles from "./index.module.scss";
interface IMenuList {
  closeMenu: () => void;
}
type Position = {
  top: number;
  left: number;
  bottom: number;
};
const MenuList = (props: IMenuList) => {
  const {
    closeMenu
  } = props;
  const headerRef = useRef(document.getElementsByTagName("header")[0]);
  const [position, setPosition] = useState<Position>({
    top: 0,
    left: 0,
    bottom: 0
  });
  const dropIn = {
    hidden: {
      transform: "translateX(-100%)",
      opacity: 0
    },
    visible: {
      transform: "translateX(0)",
      opacity: 1
    },
    exit: {
      transform: "translateX(-100%)",
      opacity: 0
    }
  };
  const ref = useRef(null);
  useEffect(() => {
    const updatePosition = () => {
      if (headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left,
          bottom: rect.bottom
        });
      }
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="MenuList" data-sentry-source-file="index.tsx">
      <motion.div className={styles.wrap} ref={ref} variants={dropIn} initial="hidden" animate="visible" exit="exit" onClick={e => e.stopPropagation()} style={{
      top: position.bottom
    }} itemScope itemType="https://schema.org/SiteNavigationElement" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <ul className={styles.list}>
          <li className={styles.item}>
            <GeoButton data-sentry-element="GeoButton" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item}>
            <MenuButton closeMenu={closeMenu} data-sentry-element="MenuButton" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item}>
            <CartButton data-sentry-element="CartButton" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu}>
            <Link prefetch={false} href="/catalog/" className={styles.button} data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Catalog"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              Каталог
            </Link>
          </li>
          <li className={styles.item}>
            <CallbackButton data-sentry-element="CallbackButton" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item}>
            <Link prefetch={false} href={supportsNumberHref} className={styles.button} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"TelHeader"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              {supportNumber}
            </Link>
          </li>
          <li className={styles.item}>
            <p className={styles.button}>
              <Icon name={"Worktime"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              Режим работы 7:00 - 22:00 (по МСК)
            </p>
          </li>
        </ul>
        <ul className={styles.list} itemProp="about" itemScope itemType="https://schema.org/ItemList">
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/mobile-app/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Приложение
            </Link>
            <meta itemProp="name" content="Приложение" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/shops/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Магазины
            </Link>
            <meta itemProp="name" content="streams" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/sales/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Акции
            </Link>
            <meta itemProp="name" content="Акции" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/new/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Новинки
            </Link>
            <meta itemProp="name" content="Новинки" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/liquidation/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Ликвидация
            </Link>
            <meta itemProp="name" content="Ликвидация" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/blog/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Блог
            </Link>
            <meta itemProp="name" content="Блог" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/live/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Трансляции
            </Link>
            <meta itemProp="name" content="streams" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
        </ul>
      </motion.div>
    </AnimatePresence>;
};
export default MenuList;