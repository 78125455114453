import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
interface ISearchPopular {
  keys: string[];
  onClickPopular: (value: string) => void;
}
const SearchPopular = (props: ISearchPopular) => {
  const {
    keys,
    onClickPopular
  } = props;
  if (!keys || !keys.length) return null;
  return <div className={styles.wrap} data-sentry-component="SearchPopular" data-sentry-source-file="index.tsx">
      <p className={styles.title}>Часто ищут</p>
      <div className={styles.buttonList}>
        {keys.map((key, index) => {
        if (index > 2) return;
        return <div key={index + "SearchPopular" + key} className={styles.buttonWrap}>
              <button className={styles.button} onClick={() => onClickPopular(key)}>
                <Icon name={"Search"} className={styles.icon} />
                <p className={styles.buttonText}>{key.replace(/[+]/g, " ")}</p>
              </button>
            </div>;
      })}
      </div>
    </div>;
};
export default SearchPopular;