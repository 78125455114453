import { request } from "../functions/request";
export const getDefaultCitiesRequest = async () => {
  return await request(`/city/index.php?store=true`);
};
export const getCityInfoRequest = async (city: string) => {
  return await request(`/geoip/city_list.php?query=${city}`);
};
export const getQueryCity = async (code: string) => {
  return await request(`/geoip/get_city_to_code.php?code=${code}`);
};
