import styles from "./index.module.scss";
import { FC } from "react";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const SearchModalSkeleton: FC = () => {
  return <div className={styles.wrap} data-sentry-component="SearchModalSkeleton" data-sentry-source-file="index.tsx">
      <SkeletonLoader containerTag="div" height={24} borderRadius={8} width="100%" mb={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      <SkeletonLoader containerTag="div" height={24} borderRadius={8} width="100%" mb={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      <SkeletonLoader containerTag="div" height={24} borderRadius={8} width="100%" mb={16} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />

      <SkeletonLoader containerTag="div" height={32} borderRadius={8} width="100%" mb={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      <SkeletonLoader containerTag="div" height={32} borderRadius={8} width="100%" mb={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      <SkeletonLoader containerTag="div" height={32} borderRadius={8} width="100%" mb={16} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />

      <SkeletonLoader containerTag="div" height={60} borderRadius={8} width="100%" mb={12} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      <SkeletonLoader containerTag="div" height={60} borderRadius={8} width="100%" data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />

      <SkeletonLoader containerTag="div" height={60} borderRadius={8} mt={24} width={180} ml={"auto"} mr={"auto"} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
    </div>;
};
export default SearchModalSkeleton;