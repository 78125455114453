import { useOpenModal } from "@/hooks/useOpenModal";
import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
import dynamic from "next/dynamic";
const CallbackPopup = dynamic(() => import("@/shared/components/popups/CallbackPopup"), {
  ssr: false
});
const CallbackButton = () => {
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  return <>
      {showModal && <CallbackPopup isOpen={showModal} onClose={handleCloseModal} />}
      <button onClick={handleOpenModal} className={styles.button}>
        <Icon name={"Callback"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        Обратный звонок
      </button>
    </>;
};
export default CallbackButton;