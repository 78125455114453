"use client";

import GreetingBar from "@/shared/components/GreetingBar";
import Geo from "@/shared/components/header/components/Geo";
import LinksList from "@/shared/components/header/components/LinksList";
import Info from "@/shared/components/header/components/Info";
import Bottom from "@/shared/components/header/components/Bottom";
import { ICatalogMiniItem, ISale } from "@/shared/types/types";
import { supportNumber } from "@/shared/constants/constants";
import styles from "./index.module.scss";
interface IContentProps {
  catalog: ICatalogMiniItem[] | false;
  sales: ISale[] | false;
  choosedCity?: string;
  isBot?: boolean;
}
const Content = (props: IContentProps) => {
  const {
    catalog,
    sales,
    choosedCity,
    isBot
  } = props;
  return <header className={styles.header} itemScope itemType="https://schema.org/WebSite" id="header" data-sentry-component="Content" data-sentry-source-file="index.tsx">
      <link itemProp="url" href="https://ohotaktiv.ru/" />
      <meta itemProp="name" content="ОхотАктив" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <div>
        <GreetingBar isMobile data-sentry-element="GreetingBar" data-sentry-source-file="index.tsx" />
        <div className={`container ${styles.container}`}>
          <div className={styles.headerTopWrap}>
            <Geo city={choosedCity} data-sentry-element="Geo" data-sentry-source-file="index.tsx" />
            <LinksList data-sentry-element="LinksList" data-sentry-source-file="index.tsx" />
            <Info data-sentry-element="Info" data-sentry-source-file="index.tsx" />
          </div>
          <Bottom catalog={catalog ? catalog : false} sales={sales ? sales : false} data-sentry-element="Bottom" data-sentry-source-file="index.tsx" />
        </div>
      </div>
      <div itemScope itemType="https://schema.org/Organization" style={{
      display: "none"
    }}>
        <span itemProp="name">ОхотАктив</span>
        <a itemProp="url" href="https://ohotaktiv.ru" />
        <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
          <span itemProp="streetAddress">г. Кострома, ул. Юбилейная, д. 28и, пом. 5</span>
          <span itemProp="addressLocality">Кострома</span>
        </div>
        <span itemProp="telephone">{supportNumber}</span>
        <span itemProp="email">info@ohotaktiv.ru</span>
      </div>
    </header>;
};
export default Content;