"use client";

import Link from "next/link";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "@/shared/redux/hooks";
import { getAuthStatus, getUserInfo } from "@/shared/redux/features/userSlice";
import { useRouter } from "next/navigation";
import { useOpenModal } from "@/hooks/useOpenModal";
import { Icon } from "@/shared/components/Icon";
import { GeoPopup } from "@/shared/components/popups";
import NewCatalogModal from "@/shared/components/CatalogModal";
import { useDisableScroll } from "@/hooks/useDisableScroll";
import { useAuthModal } from "@/hooks/useAuthModal";
import { ICatalogMiniItem, ISale } from "@/shared/types/types";
import styles from "./index.module.scss";
interface INav {
  setIsOpenMenu: (value: boolean) => void;
  catalog: ICatalogMiniItem[] | false;
  sales: ISale[] | false;
  loadCatalog: () => Promise<void>;
}
const Nav = (props: INav) => {
  const {
    setIsOpenMenu,
    catalog,
    sales,
    loadCatalog
  } = props;
  const router = useRouter();
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter(item => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  const userName = useAppSelector(getUserInfo).info.name;
  const isAuthUser = useAppSelector(getAuthStatus).isAuth;
  const {
    handleOpenAuthModal
  } = useAuthModal();
  const [showModalGeo, handleOpenModalGeo, handleCloseModalGeo] = useOpenModal();
  const [showModalCategories, handleOpenModalCategories, handleCloseModalCategories] = useOpenModal();
  const {
    disableScroll,
    enableScroll
  } = useDisableScroll();
  useEffect(() => {
    setIsClient(true);
  }, []);
  const handleClick = () => isAuthUser ? router.push("/cabinet/") : handleOpenAuthModal();
  const displayText = isClient ? isAuthUser ? userName ? userName : "Профиль" : "Войти" : "Войти";
  const closeCatalog = () => {
    enableScroll();
    handleCloseModalCategories();
  };
  const openCatalog = async () => {
    try {
      await loadCatalog();
    } catch (err) {
      console.log("Ошибка загрузки каталога", err);
    } finally {
      disableScroll();
      handleOpenModalCategories();
    }
  };
  return <>
      <div className={styles.wrap}>
        {showModalGeo ? <GeoPopup isOpen={showModalGeo} onClose={handleCloseModalGeo} /> : null}
        {showModalCategories && catalog ? <NewCatalogModal visible={showModalCategories} handleClose={closeCatalog} catalog={catalog} sales={sales} /> : null}
        <div className={styles.modal_container}></div>
        <ul className={styles.list}>
          <li className={styles.item}>
            <Link prefetch={false} href="/" className={styles.button} title="Главная" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Main"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              Главная
            </Link>
          </li>
          <li className={styles.item}>
            <button onClick={() => setIsOpenMenu(true)} className={styles.button}>
              <Icon name={"Menu"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              Меню
            </button>
          </li>
          <li className={styles.itemButton}>
            <button onClick={openCatalog} className={styles.button} title="Каталог">
              <Icon name={"Catalog"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              Каталог
            </button>
          </li>
          <li className={styles.item}>
            <Link prefetch={false} href="/cart/" className={styles.button} title="Корзина" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Cart"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              Корзина
              <span className={styles.count}>{isClient ? countCart : 0}</span>
            </Link>
          </li>
          <li className={styles.item}>
            <button title="Личный кабинет" onClick={handleClick} className={styles.button}>
              <Icon name={"Personal"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              {displayText}
            </button>
          </li>
        </ul>
      </div>
    </>;
};
export default Nav;