"use client";

import Link from "next/link";
import styles from "./sale-button.module.scss";
import { FC, useEffect, useState } from "react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { li } from "@/utils/functions/li";
import { ISaleData } from "@/shared/types/types";
interface ISaleButton {
  saleData: ISaleData;
  isBot?: boolean;
}
const applyPath = ["catalog", "card", "sales", "highlight", "brands"];
const SaleButton: FC<ISaleButton> = props => {
  const {
    saleData
  } = props;
  const [isClient, setIsClient] = useState(false);
  const pathname = usePathname();
  useEffect(() => {
    if (!isClient) {
      setIsClient(true);
    }
  }, []);
  useEffect(() => {
    if (saleData.success) {
      let findApply = false;
      applyPath.forEach(elem => {
        if (pathname.includes(elem)) {
          findApply = true;
        }
      });
      if (findApply || pathname === "/") {
        document.body.classList.add("sale-background");
      } else document.body.classList.remove("sale-background");
    }
  }, [pathname]);
  if ((!saleData || !saleData.success) && !isClient) return null;
  return <noindex data-sentry-element="noindex" data-sentry-component="SaleButton" data-sentry-source-file="sale-button.tsx">
      <Link prefetch={false} href={saleData.data.property_run_line_link} className={styles.plashka} data-sentry-element="Link" data-sentry-source-file="sale-button.tsx">
        <Image src={li(saleData.data.picture_plashka)} alt="Акция" width={100} height={100} className={styles.image} data-sentry-element="Image" data-sentry-source-file="sale-button.tsx" />
      </Link>
    </noindex>;
};
export default SaleButton;