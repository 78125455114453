import styles from "./index.module.scss";
import Image from "next/image";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import { ICatalogDetailBanners, ICatalogMiniItem, ICatalogObjDepths, ISale } from "@/shared/types/types";
import Link from "next/link";
import { Icon } from "@/shared/components/Icon";
import DetailElem from "@/shared/components/CatalogModal/components/DetailElem";
import Banner from "@/shared/components/CatalogModal/components/Banner";
import { li } from "@/utils/functions/li";
import { rejectedCatalogs } from "@/shared/helpers/data/catalogHelpers";
interface ICatalogDetail {
  isChoice: boolean;
  currentCatalogInfo: ICatalogMiniItem;
  currentCatalog: ICatalogObjDepths[];
  noDepths: boolean;
  listWithoutDepths: ICatalogObjDepths[];
  listWithDepths: ICatalogObjDepths[];
  handleClose: () => void;
  banners?: ICatalogDetailBanners[];
  sales: ISale[] | false;
  isSales: boolean;
}
const Detail = (props: ICatalogDetail) => {
  const {
    isChoice,
    currentCatalogInfo,
    currentCatalog,
    noDepths = false,
    listWithoutDepths,
    listWithDepths,
    handleClose = () => {},
    sales = [],
    isSales
  } = props;
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const isReject = rejectedCatalogs.includes(currentCatalogInfo.code);
  const banners = currentCatalogInfo.catalog_banners && currentCatalogInfo.catalog_banners.length ? currentCatalogInfo.catalog_banners : [];
  return <div className={`${styles.catalog_detail} ${isChoice ? styles.choice : ""}`} data-sentry-component="Detail" data-sentry-source-file="index.tsx">
      <div className={styles.catalog_content}>
        {!isMobile /* null */ ? <Link href={currentCatalogInfo.code === "sales" ? "/sales/" : `/catalog/${currentCatalogInfo.code}`} className={styles.hero_link} onClick={handleClose} prefetch={false}>
            {!isReject || isSales ? <p className={styles.hero_link_name}>{isSales ? "Акции" : currentCatalogInfo.name}</p> : null}
            <Image src={isSales ? li("catalog_sale_detail.png") : li(currentCatalogInfo.modal_picture)} alt={`Изображение к каталогу: ${currentCatalogInfo.name}`} width={400} height={80} className={styles.hero_link_image} />
            {!currentCatalogInfo.depths ? <span className={styles.chevron}>
                <Icon name={"Chevron"} className={styles.chevron_icon} />
              </span> : null}
          </Link> : <Link href={`/catalog/${currentCatalogInfo.code}`} className={styles.catalog_detail_name} onClick={handleClose} prefetch={false}>
            {currentCatalogInfo.name}
          </Link>}
        {isSales && sales ? <div className={styles.sales}>
            {sales.map((sale, i) => <Link prefetch={false} href={`/sales/${sale.code}`} key={i + "sale_card_" + sale.name.replace("", "_")} className={styles.sale} onClick={handleClose}>
                <Image src={li(sale.picture)} alt={sale.name} width={420} height={236} className={styles.image} />
              </Link>)}
          </div> : null}
        <div className={styles.catalogs}>
          {listWithoutDepths.length && !isMobile && !isSales ? <div className={`
                ${styles.catalog_detail_list}
                ${!isMobile && noDepths ? styles.catalog_detail__no_depth : ""}
              `}>
              {listWithoutDepths.map((item, index: number) => {
            return <DetailElem key={index + `_catalog_detail_elem`} catalog={item} newDepths={[]} handleClose={handleClose} />;
          })}
            </div> : null}
          {!isMobile && listWithDepths.length && !isSales ? <div className={`${styles.catalog_detail_list}`}>
              {listWithDepths.map((item, index: number) => {
            const newDepths = item.depths ? Object.keys(item.depths).map((depItem: string) => {
              if (item.depths) return item.depths[depItem];else return false;
            }) : false;
            return <DetailElem key={index + `_catalog_detail_elem`} catalog={item} newDepths={newDepths} handleClose={handleClose} />;
          })}
            </div> : null}
          {isMobile && !!currentCatalog.length && !isSales ? <div className={`${styles.catalog_detail_list} ${!isMobile && noDepths ? styles.catalog_detail__no_depth : ""}`}>
              {currentCatalog.map((item, index: number) => {
            const newDepths = item.depths ? Object.keys(item.depths).map((depItem: string) => {
              if (item.depths) return item.depths[depItem];else return false;
            }) : false;
            return <DetailElem key={index + `_catalog_detail_elem`} catalog={item} newDepths={newDepths} handleClose={handleClose} />;
          })}
            </div> : null}
        </div>
      </div>
      {!!banners.length && !isSales ? <div className={styles.catalog_banners}>
          {banners.map((item, index: number) => <Banner banner={item} key={index + "_catalogBanner"} handleClose={handleClose} />)}
        </div> : null}
    </div>;
};
export default Detail;