import styles from "./index.module.scss";
import Link from "next/link";
import { ICatalogDetailBanners } from "@/shared/types/types";
import Image from "next/image";
import { li } from "@/utils/functions/li";
interface ICatalogBanner {
  banner: ICatalogDetailBanners;
  handleClose: () => void;
}
const Banner = (props: ICatalogBanner) => {
  const {
    banner,
    handleClose
  } = props;
  return <Link href={banner.link} className={styles.link} onClick={handleClose} data-sentry-element="Link" data-sentry-component="Banner" data-sentry-source-file="index.tsx">
      <Image src={li(banner.img)} alt={`Баннер`} className={styles.link_banner} width={400} height={230} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </Link>;
};
export default Banner;