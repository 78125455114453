import { usePathname, useRouter } from "next/navigation";
import { Icon } from "@/shared/components/Icon";
import MenuList from "@/shared/components/header/components/MenuList";
import styles from "./index.module.scss";
interface IHeaderMenu {
  isOpenMenu: boolean;
  setIsOpenMenu: (isOpenMenu: boolean) => void;
}
const Menu = (props: IHeaderMenu) => {
  const {
    isOpenMenu,
    setIsOpenMenu
  } = props;
  const pathname = usePathname();
  const router = useRouter();
  const handleBack = () => {
    if (isOpenMenu) {
      setIsOpenMenu(!isOpenMenu);
    } else {
      if (!document.referrer) {
        router.push("/");
        return;
      }
      router.back();
    }
  };
  return <>
      <div className={styles.menu}>
        {pathname === "/" ? <button onClick={() => setIsOpenMenu(!isOpenMenu)} className={styles.button}>
            {isOpenMenu ? <Icon name={"Close"} className={styles.icon} /> : <Icon name={"Burger"} className={styles.icon} />}
          </button> : <button onClick={handleBack} className={styles.button}>
            {isOpenMenu ? <Icon name={"Close"} className={styles.icon} /> : <Icon name={"ArrowBack"} className={styles.icon} />}
          </button>}
      </div>
      {isOpenMenu && <MenuList closeMenu={() => setIsOpenMenu(false)} />}
    </>;
};
export default Menu;