"use client";

import Link from "next/link";
import { ICardMini } from "@/shared/types/types";
import { Icon } from "@/shared/components/Icon";
import SearchCard from "@/shared/components/header/components/SearchCard";
import styles from "./index.module.scss";
interface ISearchCardsList {
  items: Array<ICardMini>;
  showButton?: boolean;
  query: string;
  onClick?: () => void;
  cardMiniDisable?: boolean;
  searchRedirect?: string;
}
const SearchCardsList = (props: ISearchCardsList) => {
  const {
    items,
    showButton,
    query,
    onClick,
    cardMiniDisable,
    searchRedirect
  } = props;
  const handleCardClick = () => {
    if (onClick) {
      onClick();
    }
    ym(42989679, "reachGoal", "goingToCardFromQuickSearch");
  };
  if (!items) return null;
  return <div className={styles.wrap} data-sentry-component="SearchCardsList" data-sentry-source-file="index.tsx">
      <div className={styles.cardsList}>
        {items.length > 0 ? items.map((item, index: number) => {
        if (index > 2) return;
        return <SearchCard card={item} key={`${index + "SearchCardsList"}`} onClose={handleCardClick} />;
      }) : <p className={styles.text}>По запросу «{query}» ничего не найдено</p>}
      </div>
      {showButton && <Link prefetch={false} onClick={onClick} className={styles.link} href={searchRedirect ? searchRedirect : `/search/?query=${query}`} aria-disabled={cardMiniDisable}>
          Смотреть все результаты <Icon name={"Arrow"} className={styles.icon} />
        </Link>}
    </div>;
};
export default SearchCardsList;