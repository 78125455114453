"use client";

import { useEffect, useState } from "react";
import { useAppSelector } from "@/shared/redux/hooks";
import { getFirstTimeStatus } from "@/shared/redux/features/citySlice";
import { Icon } from "@/shared/components/Icon";
import { GeoPopup } from "@/shared/components/popups";
import FirstTime from "@/shared/components/popups/FirstTime";
import { useOpenModal } from "@/hooks/useOpenModal";
import styles from "./index.module.scss";
interface IHeaderGeo {
  city?: string;
}
const HeaderGeo = (props: IHeaderGeo) => {
  const {
    city = ""
  } = props;
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const firstTime: boolean = useAppSelector(getFirstTimeStatus);
  const [firstTimeStatus, setFirstTimeStatus] = useState<boolean>(true);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  useEffect(() => {
    setIsLoad(true);
  }, [firstTime]);
  return <>
      {showModal && <GeoPopup isOpen={showModal} onClose={handleCloseModal} />}
      {firstTimeStatus && firstTime ? <FirstTime onClose={() => setFirstTimeStatus(false)} anotherCity={handleOpenModal} /> : null}
      <div className={styles.geo}>
        <Icon name={"Geo"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        <button className={styles.button} onClick={handleOpenModal}>
          {city ? JSON.parse(city).name : "Москва"}
        </button>
      </div>
    </>;
};
export default HeaderGeo;