"use client";

import { forwardRef, useEffect, useRef, useState } from "react";
import { SearchContextProvider } from "@/shared/contexts/searchContext";
import useDebouncedFunction from "@/hooks/useDebouncedFunction";
import { useAppDispatch } from "@/shared/redux/hooks";
import { getSearchItems } from "@/utils/api/search";
import { ICardMini } from "@/shared/types/types";
import { setSearch } from "@/shared/redux/features/searchSlice";
import { usePathname, useRouter } from "next/navigation";
import { Icon } from "@/shared/components/Icon";
import SearchModal from "@/shared/components/header/components/SearchModal";
import { useDisableScroll } from "@/hooks/useDisableScroll";
import styles from "./index.module.scss";
interface IHeaderSearch {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}
const HeaderSearch = forwardRef<HTMLDivElement, IHeaderSearch>((props, ref) => {
  const {
    isOpen,
    setIsOpen
  } = props;
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    enableScroll
  } = useDisableScroll();
  const wrapRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const pathName = usePathname();
  // поисковая выдача
  const [searchItems, setSearchItems] = useState<Array<ICardMini>>([]);
  // поисковые ключи
  const [searchKeys, setSearchKeys] = useState<string[]>([]);
  // поисковый редирект если есть
  const [searchRedirect, setSearchRedirect] = useState<string>("");
  // поисковый запрос
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchCatalogs, setSearchCatalogs] = useState<{
    [key: string]: string | number;
    sort: number;
  }[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const debouncedSearch = useDebouncedFunction(value => {
    setIsFetching(true);
    setSearchQuery(value);
    if (value.length !== 0) {
      getSearchItems(value).then(res => {
        setSearchItems(res.items);
        setSearchKeys(res.keys);
        setSearchCatalogs(res.catalog);
        dispatch(setSearch(value));
        setSearchRedirect(res.redirect);
        ym(42989679, "reachGoal", "useSearch");
        setIsFetching(false);
      });
    }
  }, 333);
  const handleSearchInput = (arg: string) => {
    debouncedSearch(arg);
  };
  const handlePopularClick = (value: string) => {
    if (!inputRef.current) return;
    inputRef.current.value = value;
    debouncedSearch(value);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node) && wrapRef.current && !wrapRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    if (inputRef.current) {
      inputRef.current.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (inputRef.current) {
        inputRef.current.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [inputRef, wrapRef]);
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      if (inputRef.current && inputRef.current.value) {
        router.push("/search/?query=" + inputRef.current.value);
        setIsOpen(false);
        inputRef.current.blur();
      }
    }
  };
  useEffect(() => {
    if (!isOpen) {
      if (!searchQuery) {
        setSearchItems([]);
        setSearchKeys([]);
        setSearchQuery("");
      }
      enableScroll();
    }
  }, [isOpen]);
  useEffect(() => {
    if (typeof window !== "undefined" && inputRef.current && !window.location.search) {
      inputRef.current.value = "";
      setSearchQuery("");
    }
  }, [pathName]);
  return <SearchContextProvider>
      <div className={styles.wrap} itemProp="potentialAction" itemScope itemType="https://schema.org/SearchAction" ref={ref}>
        <meta itemProp="target" content="https://ohotaktiv.ru/search/?search={search_term_string}" />
        <input id="search-input" placeholder="Искать в ОхотАктив" className={styles.input} ref={inputRef} onChange={e => handleSearchInput(e.target.value)} itemProp="query-input" name="search_term_string" autoComplete="off" onClick={() => setIsOpen(true)} />
        {isOpen && <SearchModal items={searchItems} query={searchQuery} searchKeys={searchKeys} onClickPopular={handlePopularClick} ref={wrapRef} closeSearch={() => setIsOpen(false)} searchRedirect={searchRedirect} searchCatalogs={searchCatalogs} isFetching={isFetching} />}
        <button className={styles.searchButton} onClick={() => {
        if (inputRef.current && searchQuery) {
          router.push("/search/?query=" + inputRef.current.value);
          return;
        }
        if (inputRef.current && !searchQuery) {
          inputRef.current.focus();
          setIsOpen(true);
          return;
        }
      }} title="Поиск">
          <Icon name={"Search"} />
        </button>
      </div>
    </SearchContextProvider>;
});
HeaderSearch.displayName = "HeaderSearch";
export default HeaderSearch;