"use client";

import { useState } from "react";
import { useOpenModal } from "@/hooks/useOpenModal";
import { IHeaderLink } from "@/shared/types/types";
import { Icon } from "@/shared/components/Icon";
import { StatusOrderPopup } from "@/shared/components/popups";
import { Dropdown } from "@/shared/components/header/components/Dropdown";
import styles from "./index.module.scss";
interface IMore {
  list: IHeaderLink[];
}
const More = (props: IMore) => {
  const {
    list
  } = props;
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  return <>
      {showModal && <StatusOrderPopup isOpen={showModal} onClose={handleCloseModal} />}
      <div className={styles.dropdown}>
        <button onClick={() => setOpenDropdown(!isOpenDropdown)} className={styles.dropdownButton}>
          Ещё <Icon name={"Chevron"} className={isOpenDropdown ? styles.chevronActive : styles.chevron} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        </button>
        {isOpenDropdown && <Dropdown setOpenDropdown={setOpenDropdown} openStatusPopup={handleOpenModal} list={list} />}
      </div>
    </>;
};
export default More;