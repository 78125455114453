"use client";

import Script from "next/script";
export function RetailCRM() {
  return (
    // <Script id={"retailcrm-script"}>
    //   {`
    //     var _rcct =
    //       "b9adf30844fe7875e782f8c638ac7993b4c78c294aca1f57bfa6bfc2160b1b04";
    //     !(function(t) {
    //       var a = t.getElementsByTagName("head")[0];
    //       var c = t.createElement("script");
    //       c.type = "text/javascript";
    //       c.src = "//c.retailcrm.tech/widget/loader.js";
    //       a.appendChild(c);
    //     })(document);
    //   `}
    // </Script>
    <>
      <Script id="sounds-blocker" strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="retail-crm.tsx">
        {`
          (function() {
            const originalFetch = window.fetch;
            window.fetch = function(input) {
              if (typeof input === "string" && input.includes("/static/sounds")) {
                return Promise.resolve(new Response('', { status: 200 }));
              }
              return originalFetch.apply(this, arguments);
            };

            const originalXHROpen = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function(method, url) {
              if (typeof url === "string" && url.includes("/static/sounds")) {
                this.abort();
                return;
              }
              return originalXHROpen.apply(this, arguments);
            };
          })();
        `}
      </Script>
      <Script id="retailcrm-script" data-sentry-element="Script" data-sentry-source-file="retail-crm.tsx">
        {`
          var _rcct =
            "b9adf30844fe7875e782f8c638ac7993b4c78c294aca1f57bfa6bfc2160b1b04";
          !(function(t) {
            var a = t.getElementsByTagName("head")[0];
            var c = t.createElement("script");
            c.type = "text/javascript";
            c.src = "//c.retailcrm.tech/widget/loader.js";
            a.appendChild(c);
          })(document);
        `}
      </Script>
    </>
  );
}